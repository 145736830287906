import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { useLocation, useHistory } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import {
  getUsersById as onGetUsersById
} from "store/users/actions"

let payload = {
  "email": "",
  "contact": "",
  "isVerified": false,
  "status": "approved",
  "countrycode": "",
  "type": "user",
  "role": "advisor",
  "access_group": "intermediate",
  "lang_id": 1,
  "islr": false,
  "userdetail": {
      "firstname": "",
      "lastname": "",
      "img": "",
      "lang_id": 1,
      "document": [
          {
              "documenturl": "",
              "documenttype": "Identity Proof",
              "documentsubtype": "",
              "expirydate": "",
              "documentnumber": "",
              "fileType": "",
              "fileName": "",
              "status": "Approved",
          }
      ]
  }
}

const AddAdvisor = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const searchParams = new URLSearchParams(location.search)
  const userId = searchParams.get("id")
  const [isEdit, setIsEdit] = useState(false)
  const [idProof, setIdProof] = useState("passport")
  const [uploadedFileData, setUploadedFileData] = useState({})
  const { usersById } = useSelector(state => ({
    usersById: state.Users.usersById,
    error: state.Users.error,
  }))
  const [filePreview, setFilePreview] = useState(null);
  const [fileType, setFileType] = useState('');
  const [fileName, setFileName] = useState('');

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(`${"Please enter valid email id"}`)
      .required(`${"Email Id is required"}`),
    fname: yup.string().required(`${"Advisor first name required."}`),
    lname: yup.string().required(`${"Advisor last name required."}`),
    countryCode: yup.object()
      .shape({
        value: yup.string().required('Country code is required'),
      })
      .required('Country code is required'),
    phn: yup
      .number()
      .required(`${"Phone number is required"}`),
    
    ...(idProof.toLowerCase() === "passport" && {
      passportNum: yup
        .string()
        .required(`${"Passport number is required"}`),
      expiryDatePassport: yup
        .date()
        .min(new Date(), "Passport Expiry date cannot be in the past")
        .required(`${"Passport Expiry date is required"}`)
        .typeError("Invalid date format"),
      passportFile: yup
        .mixed()
        .required(`${"Passport file is required"}`)
        .test(
          "fileFormat",
          "Only PDF or Image file formats are allowed",
          (value) => {
            if (value) {
              const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"];
              // console.log('value', value);
              // return supportedFormats.includes(value?.name.split('.').pop());
            }
            return true;
          }
        )
        .test("fileSize", "File size must not be more than 5MB", (value) => {
          if (value) {
            // return value?.size <= 5242880;
          }
          return true;
        }),
    }),
    ...(idProof.toLowerCase() === "identitycard" && {
      idNum: yup
        .string()
        .required(`${"Identity Card is required"}`),
      expiryDateIDProof: yup
        .date()
        .min(new Date(), "Identity card date cannot be in the past")
        .required(`${"Identity card date is required"}`)
        .typeError("Invalid date format"),
      idFile: yup
        .mixed()
        .required(`${"Identity card file is required"}`),
    }),
  });

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      email: "",
      fname: "",
      lname: "",
      countryCode: {value: "+91", label: `India (+91)`},
      phn: "",

      documentId: null,
      passportNum: "",
      expiryDatePassport: "",
      expiryDateIDProof: "",
      idNum: "",
      passportFile: "",
      idFile: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("values", values);
      console.log('uploadedFileData', uploadedFileData);

      if(!isEdit) {
        payload.email = values?.email;
        payload.contact = values?.phn;
        payload.countrycode = values?.countryCode?.value;
        payload["userdetail"].firstname = values?.fname,
        payload["userdetail"].lastname = values?.lname,

        payload["userdetail"].document[0].documenturl = uploadedFileData?.fileurl;
        payload["userdetail"].document[0].documentsubtype = idProof;
        payload["userdetail"].document[0].expirydate = (idProof.toLowerCase() === "passport") ? values?.expiryDatePassport : values?.expiryDateIDProof;
        payload["userdetail"].document[0].documentnumber = (idProof.toLowerCase() === "passport") ? values?.passportNum : values?.idNum;
        payload["userdetail"].document[0].fileType = uploadedFileData?.filetype;
        payload["userdetail"].document[0].fileName = uploadedFileData?.filename;

        console.log('payload', payload);
        axios
          .post("https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/user/add", payload, {
            headers: {
              Authorization: `Bearer ${JSON.parse(localStorage.getItem("authUser")).accesstoken}`,
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (response.data.success) {
              formik.resetForm();
              toast.success("Advisor added successfully...!")
              history.push("/users")
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            toast.error(error.response.data.error)
          });
      } else {
        let payload = JSON.parse(JSON.stringify(usersById[0]));
        delete(payload?.email)
        delete(payload?.userdetail?.userlrdetail)
        payload.contact = values?.phn;
        payload.countrycode = values?.countryCode?.value;
        payload["userdetail"].firstname = values?.fname,
        payload["userdetail"].lastname = values?.lname,
        payload.status = "approved";

        if(Object.keys(uploadedFileData).length > 0) {
          payload["userdetail"].document[0].documenturl = uploadedFileData?.fileurl;
          payload["userdetail"].document[0].fileType = uploadedFileData?.filetype;
          payload["userdetail"].document[0].fileName = uploadedFileData?.filename;
        }
        payload["userdetail"].document[0].documentsubtype = idProof;
        payload["userdetail"].document[0].expirydate = (idProof.toLowerCase() === "passport") ? values?.expiryDatePassport : values?.expiryDateIDProof;
        payload["userdetail"].document[0].documentnumber = (idProof.toLowerCase() === "passport") ? values?.passportNum : values?.idNum;

        console.log('update payload', payload);
        axios
          .put("https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/user", payload, {
            headers: {
              Authorization: `Bearer ${JSON.parse(localStorage.getItem("authUser")).accesstoken}`,
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (response.data.success) {
              formik.resetForm();
              toast.success("Advisor details updated successfully...!")
              history.push("/users")
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            toast.error(error.response.data.error)
          });
      }
    }
  });

  const changeHandle = e => {
    console.log('e.target.id', e.target.id);
    if (e.target.id === "passport") {
      setIdProof("passport")
    } else {
      setIdProof("identityCard")
    }
  }

  const countryList = [
    { code: "AD", name: "Andorra", countryCode: "+376" },
    { code: "AE", name: "United Arab Emirates", countryCode: "+971" },
    { code: "AF", name: "Afghanistan", countryCode: "+93" },
    { code: "AG", name: "Antigua and Barbuda", countryCode: "+1-268" },
    { code: "AI", name: "Anguilla", countryCode: "+1-264" },
    { code: "AL", name: "Albania", countryCode: "+355" },
    { code: "AM", name: "Armenia", countryCode: "+374" },
    { code: "AO", name: "Angola", countryCode: "+244" },
    { code: "AQ", name: "Antarctica", countryCode: "+672" },
    { code: "AR", name: "Argentina", countryCode: "+54" },
    { code: "AS", name: "American Samoa", countryCode: "+1-684" },
    { code: "AT", name: "Austria", countryCode: "+43" },
    { code: "AU", name: "Australia", countryCode: "+61" },
    { code: "AW", name: "Aruba", countryCode: "+297" },
    { code: "AX", name: "Alland Islands", countryCode: "+358" },
    { code: "AZ", name: "Azerbaijan", countryCode: "+994" },
    { code: "BA", name: "Bosnia and Herzegovina", countryCode: "+387" },
    { code: "BB", name: "Barbados", countryCode: "+1-246" },
    { code: "BD", name: "Bangladesh", countryCode: "+880" },
    { code: "BE", name: "Belgium", countryCode: "+32" },
    { code: "BF", name: "Burkina Faso", countryCode: "+226" },
    { code: "BG", name: "Bulgaria", countryCode: "+359" },
    { code: "BH", name: "Bahrain", countryCode: "+973" },
    { code: "BI", name: "Burundi", countryCode: "+257" },
    { code: "BJ", name: "Benin", countryCode: "+229" },
    { code: "BL", name: "Saint Barthelemy", countryCode: "+590" },
    { code: "BM", name: "Bermuda", countryCode: "+1-441" },
    { code: "BN", name: "Brunei Darussalam", countryCode: "+673" },
    { code: "BO", name: "Bolivia", countryCode: "+591" },
    { code: "BR", name: "Brazil", countryCode: "+55" },
    { code: "BS", name: "Bahamas", countryCode: "+1-242" },
    { code: "BT", name: "Bhutan", countryCode: "+975" },
    { code: "BV", name: "Bouvet Island", countryCode: "+47" },
    { code: "BW", name: "Botswana", countryCode: "+267" },
    { code: "BY", name: "Belarus", countryCode: "+375" },
    { code: "BZ", name: "Belize", countryCode: "+501" },
    { code: "CA", name: "Canada", countryCode: "+1" },
    { code: "CC", name: "Cocos (Keeling) Islands", countryCode: "+61" },
    {
      code: "CD",
      name: "Congo, Democratic Republic of the",
      countryCode: "+243",
    },
    { code: "CF", name: "Central African Republic", countryCode: "+236" },
    { code: "CG", name: "Congo, Republic of the", countryCode: "+242" },
    { code: "CH", name: "Switzerland", countryCode: "+41" },
    { code: "CI", name: "Cote d'Ivoire", countryCode: "+225" },
    { code: "CK", name: "Cook Islands", countryCode: "+682" },
    { code: "CL", name: "Chile", countryCode: "+56" },
    { code: "CM", name: "Cameroon", countryCode: "+237" },
    { code: "CN", name: "China", countryCode: "+86" },
    { code: "CO", name: "Colombia", countryCode: "+57" },
    { code: "CR", name: "Costa Rica", countryCode: "+506" },
    { code: "CU", name: "Cuba", countryCode: "+53" },
    { code: "CV", name: "Cape Verde", countryCode: "+238" },
    { code: "CW", name: "Curacao", countryCode: "+599" },
    { code: "CX", name: "Christmas Island", countryCode: "+61" },
    { code: "CY", name: "Cyprus", countryCode: "+357" },
    { code: "CZ", name: "Czech Republic", countryCode: "+420" },
    { code: "DE", name: "Germany", countryCode: "+49" },
    { code: "DJ", name: "Djibouti", countryCode: "+253" },
    { code: "DK", name: "Denmark", countryCode: "+45" },
    { code: "DM", name: "Dominica", countryCode: "+1-767" },
    { code: "DO", name: "Dominican Republic", countryCode: "+1-809" },
    { code: "DZ", name: "Algeria", countryCode: "+213" },
    { code: "EC", name: "Ecuador", countryCode: "+593" },
    { code: "EE", name: "Estonia", countryCode: "+372" },
    { code: "EG", name: "Egypt", countryCode: "+20" },
    { code: "EH", name: "Western Sahara", countryCode: "+212" },
    { code: "ER", name: "Eritrea", countryCode: "+291" },
    { code: "ES", name: "Spain", countryCode: "+34" },
    { code: "ET", name: "Ethiopia", countryCode: "+251" },
    { code: "FI", name: "Finland", countryCode: "+358" },
    { code: "FJ", name: "Fiji", countryCode: "+679" },
    {
      code: "FK",
      name: "Falkland Islands (Malvinas)",
      countryCode: "+500",
    },
    {
      code: "FM",
      name: "Micronesia, Federated States of",
      countryCode: "+691",
    },
    { code: "FO", name: "Faroe Islands", countryCode: "+298" },
    {
      code: "FR",
      name: "France",
      countryCode: "+33",
      suggested: true,
    },
    { code: "GA", name: "Gabon", countryCode: "+241" },
    { code: "GB", name: "United Kingdom", countryCode: "+44" },
    { code: "GD", name: "Grenada", countryCode: "+1-473" },
    { code: "GE", name: "Georgia", countryCode: "+995" },
    { code: "GF", name: "French Guiana", countryCode: "+594" },
    { code: "GG", name: "Guernsey", countryCode: "+44" },
    { code: "GH", name: "Ghana", countryCode: "+233" },
    { code: "GI", name: "Gibraltar", countryCode: "+350" },
    { code: "GL", name: "Greenland", countryCode: "+299" },
    { code: "GM", name: "Gambia", countryCode: "+220" },
    { code: "GN", name: "Guinea", countryCode: "+224" },
    { code: "GP", name: "Guadeloupe", countryCode: "+590" },
    { code: "GQ", name: "Equatorial Guinea", countryCode: "+240" },
    { code: "GR", name: "Greece", countryCode: "+30" },
    {
      code: "GS",
      name: "South Georgia and the South Sandwich Islands",
      countryCode: "+500",
    },
    { code: "GT", name: "Guatemala", countryCode: "+502" },
    { code: "GU", name: "Guam", countryCode: "+1-671" },
    { code: "GW", name: "Guinea-Bissau", countryCode: "+245" },
    { code: "GY", name: "Guyana", countryCode: "+592" },
    { code: "HK", name: "Hong Kong", countryCode: "+852" },
    {
      code: "HM",
      name: "Heard Island and McDonald Islands",
      countryCode: "+672",
    },
    { code: "HN", name: "Honduras", countryCode: "+504" },
    { code: "HR", name: "Croatia", countryCode: "+385" },
    { code: "HT", name: "Haiti", countryCode: "+509" },
    { code: "HU", name: "Hungary", countryCode: "+36" },
    { code: "ID", name: "Indonesia", countryCode: "+62" },
    { code: "IE", name: "Ireland", countryCode: "+353" },
    { code: "IL", name: "Israel", countryCode: "+972" },
    { code: "IM", name: "Isle of Man", countryCode: "+44" },
    { code: "IN", name: "India", countryCode: "+91" },
    {
      code: "IO",
      name: "British Indian Ocean Territory",
      countryCode: "+246",
    },
    { code: "IQ", name: "Iraq", countryCode: "+964" },
    {
      code: "IR",
      name: "Iran, Islamic Republic of",
      countryCode: "+98",
    },
    { code: "IS", name: "Iceland", countryCode: "+354" },
    { code: "IT", name: "Italy", countryCode: "+39" },
    { code: "JE", name: "Jersey", countryCode: "+44" },
    { code: "JM", name: "Jamaica", countryCode: "+1-876" },
    { code: "JO", name: "Jordan", countryCode: "+962" },
    {
      code: "JP",
      name: "Japan",
      countryCode: "+81",
      suggested: true,
    },
    { code: "KE", name: "Kenya", countryCode: "+254" },
    { code: "KG", name: "Kyrgyzstan", countryCode: "+996" },
    { code: "KH", name: "Cambodia", countryCode: "+855" },
    { code: "KI", name: "Kiribati", countryCode: "+686" },
    { code: "KM", name: "Comoros", countryCode: "+269" },
    {
      code: "KN",
      name: "Saint Kitts and Nevis",
      countryCode: "+1-869",
    },
    {
      code: "KP",
      name: "Korea, Democratic People's Republic of",
      countryCode: "+850",
    },
    { code: "KR", name: "Korea, Republic of", countryCode: "+82" },
    { code: "KW", name: "Kuwait", countryCode: "+965" },
    { code: "KY", name: "Cayman Islands", countryCode: "+1-345" },
    { code: "KZ", name: "Kazakhstan", countryCode: "+7" },
    {
      code: "LA",
      name: "Lao People's Democratic Republic",
      countryCode: "+856",
    },
    { code: "LB", name: "Lebanon", countryCode: "+961" },
    { code: "LC", name: "Saint Lucia", countryCode: "+1-758" },
    { code: "LI", name: "Liechtenstein", countryCode: "+423" },
    { code: "LK", name: "Sri Lanka", countryCode: "+94" },
    { code: "LR", name: "Liberia", countryCode: "+231" },
    { code: "LS", name: "Lesotho", countryCode: "+266" },
    { code: "LT", name: "Lithuania", countryCode: "+370" },
    { code: "LU", name: "Luxembourg", countryCode: "+352" },
    { code: "LV", name: "Latvia", countryCode: "+371" },
    { code: "LY", name: "Libya", countryCode: "+218" },
    { code: "MA", name: "Morocco", countryCode: "+212" },
    { code: "MC", name: "Monaco", countryCode: "+377" },
    {
      code: "MD",
      name: "Moldova, Republic of",
      countryCode: "+373",
    },
    { code: "ME", name: "Montenegro", countryCode: "+382" },
    {
      code: "MF",
      name: "Saint Martin (French part)",
      countryCode: "+590",
    },
    { code: "MG", name: "Madagascar", countryCode: "+261" },
    { code: "MH", name: "Marshall Islands", countryCode: "+692" },
    {
      code: "MK",
      name: "Macedonia, the Former Yugoslav Republic of",
      countryCode: "+389",
    },
    { code: "ML", name: "Mali", countryCode: "+223" },
    { code: "MM", name: "Myanmar", countryCode: "+95" },
    { code: "MN", name: "Mongolia", countryCode: "+976" },
    { code: "MO", name: "Macao", countryCode: "+853" },
    {
      code: "MP",
      name: "Northern Mariana Islands",
      countryCode: "+1-670",
    },
    { code: "MQ", name: "Martinique", countryCode: "+596" },
    { code: "MR", name: "Mauritania", countryCode: "+222" },
    { code: "MS", name: "Montserrat", countryCode: "+1-664" },
    { code: "MT", name: "Malta", countryCode: "+356" },
    { code: "MU", name: "Mauritius", countryCode: "+230" },
    { code: "MV", name: "Maldives", countryCode: "+960" },
    { code: "MW", name: "Malawi", countryCode: "+265" },
    { code: "MX", name: "Mexico", countryCode: "+52" },
    { code: "MY", name: "Malaysia", countryCode: "+60" },
    { code: "MZ", name: "Mozambique", countryCode: "+258" },
    { code: "NA", name: "Namibia", countryCode: "+264" },
    { code: "NC", name: "New Caledonia", countryCode: "+687" },
    { code: "NE", name: "Niger", countryCode: "+227" },
    { code: "NF", name: "Norfolk Island", countryCode: "+672" },
    { code: "NG", name: "Nigeria", countryCode: "+234" },
    { code: "NI", name: "Nicaragua", countryCode: "+505" },
    { code: "NL", name: "Netherlands", countryCode: "+31" },
    { code: "NO", name: "Norway", countryCode: "+47" },
    { code: "NP", name: "Nepal", countryCode: "+977" },
    { code: "NR", name: "Nauru", countryCode: "+674" },
    { code: "NU", name: "Niue", countryCode: "+683" },
    { code: "NZ", name: "New Zealand", countryCode: "+64" },
    { code: "OM", name: "Oman", countryCode: "+968" },
    { code: "PA", name: "Panama", countryCode: "+507" },
    { code: "PE", name: "Peru", countryCode: "+51" },
    { code: "PF", name: "French Polynesia", countryCode: "+689" },
    { code: "PG", name: "Papua New Guinea", countryCode: "+675" },
    { code: "PH", name: "Philippines", countryCode: "+63" },
    { code: "PK", name: "Pakistan", countryCode: "+92" },
    { code: "PL", name: "Poland", countryCode: "+48" },
    {
      code: "PM",
      name: "Saint Pierre and Miquelon",
      countryCode: "+508",
    },
    { code: "PN", name: "Pitcairn", countryCode: "+870" },
    { code: "PR", name: "Puerto Rico", countryCode: "+1" },
    {
      code: "PS",
      name: "Palestine, State of",
      countryCode: "+970",
    },
    { code: "PT", name: "Portugal", countryCode: "+351" },
    { code: "PW", name: "Palau", countryCode: "+680" },
    { code: "PY", name: "Paraguay", countryCode: "+595" },
    { code: "QA", name: "Qatar", countryCode: "+974" },
    { code: "RE", name: "Reunion", countryCode: "+262" },
    { code: "RO", name: "Romania", countryCode: "+40" },
    { code: "RS", name: "Serbia", countryCode: "+381" },
    { code: "RU", name: "Russian Federation", countryCode: "+7" },
    { code: "RW", name: "Rwanda", countryCode: "+250" },
    { code: "SA", name: "Saudi Arabia", countryCode: "+966" },
    { code: "SB", name: "Solomon Islands", countryCode: "+677" },
    { code: "SC", name: "Seychelles", countryCode: "+248" },
    { code: "SD", name: "Sudan", countryCode: "+249" },
    { code: "SE", name: "Sweden", countryCode: "+46" },
    { code: "SG", name: "Singapore", countryCode: "+65" },
    { code: "SH", name: "Saint Helena", countryCode: "+290" },
    { code: "SI", name: "Slovenia", countryCode: "+386" },
    {
      code: "SJ",
      name: "Svalbard and Jan Mayen",
      countryCode: "+47",
    },
    { code: "SK", name: "Slovakia", countryCode: "+421" },
    { code: "SL", name: "Sierra Leone", countryCode: "+232" },
    { code: "SM", name: "San Marino", countryCode: "+378" },
    { code: "SN", name: "Senegal", countryCode: "+221" },
    { code: "SO", name: "Somalia", countryCode: "+252" },
    { code: "SR", name: "Suriname", countryCode: "+597" },
    { code: "SS", name: "South Sudan", countryCode: "+211" },
    {
      code: "ST",
      name: "Sao Tome and Principe",
      countryCode: "+239",
    },
    { code: "SV", name: "El Salvador", countryCode: "+503" },
    {
      code: "SX",
      name: "Sint Maarten (Dutch part)",
      countryCode: "+1-721",
    },
    {
      code: "SY",
      name: "Syrian Arab Republic",
      countryCode: "+963",
    },
    { code: "SZ", name: "Swaziland", countryCode: "+268" },
    {
      code: "TC",
      name: "Turks and Caicos Islands",
      countryCode: "+1-649",
    },
    { code: "TD", name: "Chad", countryCode: "+235" },
    {
      code: "TF",
      name: "French Southern Territories",
      countryCode: "+262",
    },
    { code: "TG", name: "Togo", countryCode: "+228" },
    { code: "TH", name: "Thailand", countryCode: "+66" },
    { code: "TJ", name: "Tajikistan", countryCode: "+992" },
    { code: "TK", name: "Tokelau", countryCode: "+690" },
    { code: "TL", name: "Timor-Leste", countryCode: "+670" },
    { code: "TM", name: "Turkmenistan", countryCode: "+993" },
    { code: "TN", name: "Tunisia", countryCode: "+216" },
    { code: "TO", name: "Tonga", countryCode: "+676" },
    { code: "TR", name: "Turkey", countryCode: "+90" },
    {
      code: "TT",
      name: "Trinidad and Tobago",
      countryCode: "+1-868",
    },
    { code: "TV", name: "Tuvalu", countryCode: "+688" },
    {
      code: "TW",
      name: "Taiwan",
      countryCode: "+886",
    },
    {
      code: "TZ",
      name: "United Republic of Tanzania",
      countryCode: "+255",
    },
    { code: "UA", name: "Ukraine", countryCode: "+380" },
    { code: "UG", name: "Uganda", countryCode: "+256" },
    {
      code: "US",
      name: "United States",
      countryCode: "+1",
      suggested: true,
    },
    { code: "UY", name: "Uruguay", countryCode: "+598" },
    { code: "UZ", name: "Uzbekistan", countryCode: "+998" },
    {
      code: "VA",
      name: "Holy See (Vatican City State)",
      countryCode: "+379",
    },
    {
      code: "VC",
      name: "Saint Vincent and the Grenadines",
      countryCode: "+1-784",
    },
    { code: "VE", name: "Venezuela", countryCode: "+58" },
    {
      code: "VG",
      name: "British Virgin Islands",
      countryCode: "+1-284",
    },
    {
      code: "VI",
      name: "US Virgin Islands",
      countryCode: "+1-340",
    },
    { code: "VN", name: "Vietnam", countryCode: "+84" },
    { code: "VU", name: "Vanuatu", countryCode: "+678" },
    { code: "WF", name: "Wallis and Futuna", countryCode: "+681" },
    { code: "WS", name: "Samoa", countryCode: "+685" },
    { code: "XK", name: "Kosovo", countryCode: "+383" },
    { code: "YE", name: "Yemen", countryCode: "+967" },
    { code: "YT", name: "Mayotte", countryCode: "+262" },
    { code: "ZA", name: "South Africa", countryCode: "+27" },
    { code: "ZM", name: "Zambia", countryCode: "+260" },
    { code: "ZW", name: "Zimbabwe", countryCode: "+263" },
  ];

  const mobileNumberOptions = countryList.map(country => ({
    value: country.countryCode,
    label: `${country.name} (${country.countryCode})`
  }));

  const handleUpload = (e, fieldRef) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        toast.error("File size exceeded");
        return;
      }
      if (!file.type.includes("image") && !file.type.includes("pdf")) {
        formik.setFieldError(fieldRef, "File format not allowed");
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", file.type.includes("pdf") ? "document" : "images");

      axios.post("https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/documentUpload", formData, {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("authUser")).accesstoken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then(res => {
        if (res.data.success) {
          formik.setFieldValue(fieldRef, e.target.files);
          setUploadedFileData(res.data.data);

          if (file) {
            const reader = new FileReader();
            const fileUrl = URL.createObjectURL(file);
      
            reader.onloadend = () => {
              setFilePreview(fileUrl);
              setFileType(file.type);
              setFileName(res.data.data.filename)
            };
      
            reader.readAsDataURL(file); // Read the file as a data URL
          }
        }
      })
      .catch(error => {
        formik.setFieldError(fieldRef, "Something went wrong! Please try again.");
        console.error("Error:", error.message);
      });
    }
  };

  // Utility function to get today's date in yyyy-mm-dd format
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = ('0' + (today.getMonth() + 1)).slice(-2); // Add leading zero
    const day = ('0' + today.getDate()).slice(-2); // Add leading zero
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (userId !== null && userId !== undefined) {
      //call api to get company data;
      dispatch(onGetUsersById(userId))
      setIsEdit(true);
    }
  }, [userId])

  useEffect(() => {
    if (usersById.length > 0 && usersById !== null && userId !== null && userId !== undefined) {
      console.log('usersById', usersById[0])
      const existsCountryCode = countryList.find(obj => obj.countryCode === usersById[0]?.countrycode);
      const countryCodeObj = {
        value: existsCountryCode.countryCode,
        label: `${existsCountryCode.name} (${existsCountryCode.countryCode})`
      }
      setIdProof(usersById[0]?.userdetail?.document[0]?.documentsubtype);

      formik.setFieldValue('email', usersById[0]?.email);
      formik.setFieldValue('fname', usersById[0]?.userdetail?.firstname);
      formik.setFieldValue('lname', usersById[0]?.userdetail?.lastname);
      formik.setFieldValue('countryCode', countryCodeObj);
      formik.setFieldValue('phn', usersById[0]?.contact);
      // if(usersById[0]?.userdetail?.document[0]?.documentsubtype === "Passport") {
      //   formik.setFieldValue(usersById[0]?.userdetail?.document[0]?.documentsubtype === "Passport" ? 'passport' : 'idNum', usersById[0]?.userdetail?.document[0]?.documentnumber);
      //   formik.setFieldValue(usersById[0]?.userdetail?.document[0]?.documentsubtype === "Passport" ? 'expiryDatePassport' : 'expiryDateIDProof', new Date(usersById[0]?.userdetail?.document[0]?.expirydate));
      //   formik.setFieldValue(usersById[0]?.userdetail?.document[0]?.documentsubtype === "Passport" ? 'passportFile' : 'idFile', usersById[0]?.userdetail?.document[0]?.documenturl);
      // } else {
      //   formik.setFieldValue('idNum', usersById[0]?.userdetail?.document[0]?.documentnumber);
      //   formik.setFieldValue('expiryDateIDProof', new Date(usersById[0]?.userdetail?.document[0]?.documentnumber));
      //   formik.setFieldValue('idFile', usersById[0]?.userdetail?.document[0]?.documentnumber);
      // }
      formik.setFieldValue(usersById[0]?.userdetail?.document[0]?.documentsubtype === "passport" ? 'passportNum' : 'idNum', usersById[0]?.userdetail?.document[0]?.documentnumber);
      formik.setFieldValue(usersById[0]?.userdetail?.document[0]?.documentsubtype === "passport" ? 'expiryDatePassport' : 'expiryDateIDProof', usersById[0]?.userdetail?.document[0]?.expirydate);
      // formik.setFieldValue(usersById[0]?.userdetail?.document[0]?.documentsubtype === "Passport" ? 'passportFile' : 'idFile', getImageFileFromUrl(usersById[0]?.userdetail?.document[0]?.documenturl, usersById[0]?.userdetail?.document[0]?.fileName));

      setFilePreview(usersById[0]?.userdetail?.document[0]?.documenturl);
      const urlParts = usersById[0]?.userdetail?.document[0]?.documenturl.split('.');
      const ext = urlParts[urlParts.length - 1];
      const mimeType = ext === 'pdf' ? 'application/pdf' : 'image/' + ext;
      setFileType(mimeType);
      setFileName(usersById[0]?.userdetail?.document[0]?.fileName)
      formik.setFieldValue(usersById[0]?.userdetail?.document[0]?.documentsubtype === "passport" ? 'passportFile' : 'idFile', usersById[0]?.userdetail?.document[0]?.documenturl)
    }
  }, [usersById])

  const renderPreview = () => {
    if (!filePreview) return null;

    return (
      <div>
        {fileType.startsWith('image/') ? (
          <img
            src={filePreview}
            alt="Preview"
            style={{ width: '50px', maxHeight: '50px', marginTop: '10px' }}
          />
        ) : fileType.startsWith('application/pdf') ? (
          <iframe
            src={filePreview}
            style={{ width: '50px', maxHeight: '50px', marginTop: '10px' }}
            title="PDF Preview"
          />
        ) : (
          <div>File preview not available for this type</div>
        )}
        <div style={{ marginTop: '5px' }}>{fileName}</div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Add Advisor</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          {isEdit ? (
            <Breadcrumbs title="Advisor" breadcrumbItem="Edit Advisor" />
          ) : (
            <Breadcrumbs title="Advisor" breadcrumbItem="Add Advisor" />
          )}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Form onSubmit={formik.handleSubmit}>
                    <FormGroup className="mb-4">
                      <div className="row ">
                        <Label
                          htmlFor="email"
                          className="col-form-label col-lg-8"
                        >
                          Email
                        </Label>
                        <Col lg="10">
                          <Input
                            id="email"
                            name="email"
                            type="text"
                            placeholder="Enter Email..."
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={`${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                            invalid={formik.touched.email && Boolean(formik.errors.email)}
                            disabled={isEdit}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <FormFeedback>{formik.errors.email}</FormFeedback>
                          ) : null}
                        </Col>
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-4">
                      <div className="row ">
                        <div className="col-lg-4">
                          <Label htmlFor="fname" className="col-form-label">
                            First Name
                          </Label>
                          <Col lg="10">
                            <Input
                              id="fname"
                              name="fname"
                              type="text"
                              placeholder="Enter First Name..."
                              value={formik.values.fname}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className={`${formik.touched.fname && formik.errors.fname ? 'is-invalid' : ''}`}
                              invalid={formik.touched.fname && Boolean(formik.errors.fname)}
                            />
                            {formik.touched.fname && formik.errors.fname ? (
                              <FormFeedback>{formik.errors.fname}</FormFeedback>
                            ) : null}
                          </Col>
                        </div>
                        <div className="col-lg-4">
                          <Label htmlFor="lname" className="col-form-label">
                            Last Name
                          </Label>
                          <Col lg="10">
                            <Input
                              id="lname"
                              name="lname"
                              type="text"
                              placeholder="Enter Last Name..."
                              value={formik.values.lname}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className={`${formik.touched.lname && formik.errors.lname ? 'is-invalid' : ''}`}
                              invalid={formik.touched.lname && Boolean(formik.errors.lname)}
                            />
                            {formik.touched.lname && formik.errors.lname ? (
                              <FormFeedback>{formik.errors.lname}</FormFeedback>
                            ) : null}
                          </Col>
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-4">
                      <div className="row ">
                        <div className="col-lg-3">
                          <Label className="col-form-label col-lg-12" htmlFor="countryCode">
                            Country Code
                          </Label>
                          <Col >
                            <Select
                              isMulti={false}
                              id="countryCode"
                              name="countryCode"
                              options={mobileNumberOptions}
                              placeholder="Select country code"
                              value={formik.values.countryCode}
                              onChange={option => formik.setFieldValue('countryCode', option)}
                              onBlur={() => formik.setFieldTouched('countryCode', true)}
                              className={formik.touched.countryCode && formik.errors.countryCode ? 'is-invalid' : ''}
                              // invalid={formik.touched.countryCode && Boolean(formik.errors.countryCode)}
                            />
                            {formik.touched.countryCode && formik.errors.countryCode ? (
                              <FormFeedback>{formik.errors.countryCode}</FormFeedback>
                            ) : null}
                          </Col>
                        </div>
                        <div className="col-lg-5">
                          <Label htmlFor="phn" className="col-form-label">
                            Mobile Number
                          </Label>
                          <Input
                            id="phn"
                            name="phn"
                            type="text"
                            placeholder="Enter Mobile number..."
                            value={formik.values.phn}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={`${formik.touched.phn && formik.errors.phn ? 'is-invalid' : ''}`}
                            invalid={formik.touched.phn && Boolean(formik.errors.phn)}
                          />
                          {formik.touched.phn && formik.errors.phn ? (
                            <FormFeedback>{formik.errors.phn}</FormFeedback>
                          ) : null}
                        </div>
                      </div>
                    </FormGroup>
                    <div className="mb-4">
                      <h2 className="mb-4">Identity Proof</h2>
                      <div className="mb-3">
                        <div className="col-lg-4 mb-2">
                          <FormGroup check>
                            <Input
                              id="passport"
                              name="idproof"
                              type="radio"
                              onClick={() => {setIdProof("passport")}}
                              checked={idProof === 'passport'}
                              readOnly
                            />
                            <Label check for="passport">
                              Passport
                            </Label>
                          </FormGroup>
                        </div>
                        <div className="col-lg-4">
                          <FormGroup check>
                            <Input
                              id="identityCard"
                              name="idproof"
                              type="radio"
                              onClick={() => {setIdProof("identityCard")}}
                              checked={idProof === 'identityCard'}
                              readOnly
                            />
                            <Label check for="identityCard">
                              Identity Card
                            </Label>
                          </FormGroup>
                        </div>
                      </div>
                      {idProof === "passport" && (
                        <FormGroup className="mb-4">
                          <div className="row mb-4">
                            <div className="col-lg-8">
                              <Label htmlFor="passportNum" className="col-form-label">
                                Passport Number
                              </Label>
                              <Input
                                id="passportNum"
                                name="passportNum"
                                type="text"
                                placeholder="Enter Passport number..."
                                value={formik.values.passportNum}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={`${formik.touched.passportNum && formik.errors.passportNum ? 'is-invalid' : ''}`}
                                invalid={formik.touched.passportNum && Boolean(formik.errors.passportNum)}
                              />
                              {formik.touched.passportNum && formik.errors.passportNum ? (
                                <FormFeedback>{formik.errors.passportNum}</FormFeedback>
                              ) : null}
                            </div>
                          </div>
                          <div className="row mb-4">
                            <div className="col-lg-8">
                              <Label htmlFor="ppdate" className="col-form-label">
                                Passport Expiry
                              </Label>
                              <Input
                                id="expiryDatePassport"
                                name="expiryDatePassport"
                                type="date"
                                min={getTodayDate()}
                                value={formik.values.expiryDatePassport}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                invalid={formik.touched.expiryDatePassport && formik.errors.expiryDatePassport ? true : false}
                                className={`${formik.touched.expiryDatePassport && formik.errors.expiryDatePassport ? 'is-invalid' : ''}`}
                              />
                              {formik.touched.expiryDatePassport && formik.errors.expiryDatePassport ? (
                                <FormFeedback>{formik.errors.expiryDatePassport}</FormFeedback>
                              ) : null}
                            </div>
                          </div>
                          <div className="row mb-4">
                            <div className="col-lg-8">
                              <Label htmlFor="passportFile" className="col-form-label">
                                Passport Document
                              </Label>
                              <Input
                                id="passportFile"
                                name="passportFile"
                                type="file"
                                accept="image/*,application/pdf"
                                // invalid={formik.touched.passportFile && Boolean(formik.errors.passportFile)}
                                onChange={e => handleUpload(e, 'passportFile')}
                                onBlur={formik.handleBlur}
                                className={formik.touched.passportFile && formik.errors.passportFile ? 'is-invalid' : ''}
                              />
                              {renderPreview()}
                              {formik.touched.passportFile && formik.errors.passportFile ? (
                                <FormFeedback>{formik.errors.passportFile}</FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </FormGroup>
                      )}
                      {idProof === "identityCard" && (
                        <FormGroup className="mb-4">
                          <div className="row mb-4">
                            <div className="col-lg-8">
                              <Label htmlFor="idnum" className="col-form-label">
                                Identity Card Number
                              </Label>
                              <Input
                                id="idNum"
                                name="idNum"
                                type="text"
                                placeholder="Enter ID number..."
                                value={formik.values.idNum}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={`${formik.touched.idNum && formik.errors.idNum ? 'is-invalid' : ''}`}
                                invalid={formik.touched.idNum && Boolean(formik.errors.idNum)}
                              />
                              {formik.touched.idNum && formik.errors.idNum ? (
                                <FormFeedback>{formik.errors.idNum}</FormFeedback>
                              ) : null}
                            </div>
                          </div>
                          <div className="row mb-4">
                            <div className="col-lg-8">
                              <Label htmlFor="expiryDateIDProof" className="col-form-label">
                                ID Expiry
                              </Label>
                              <Input
                                id="expiryDateIDProof"
                                name="expiryDateIDProof"
                                type="date"
                                min={getTodayDate()}
                                value={formik.values.expiryDateIDProof}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                invalid={formik.touched.expiryDateIDProof && formik.errors.expiryDateIDProof ? true : false}
                                className={`${formik.touched.expiryDateIDProof && formik.errors.expiryDateIDProof ? 'is-invalid' : ''}`}
                              />
                              {formik.touched.expiryDateIDProof && formik.errors.expiryDateIDProof ? (
                                <FormFeedback>{formik.errors.expiryDateIDProof}</FormFeedback>
                              ) : null}
                            </div>
                          </div>
                          <div className="row mb-4">
                            <div className="col-lg-8">
                              <Label htmlFor="idFile" className="col-form-label">
                                ID Document
                              </Label>
                              <Input
                                id="idFile"
                                name="idFile"
                                type="file"
                                accept="image/*,application/pdf"
                                // invalid={formik.touched.idFile && Boolean(formik.errors.idFile)}
                                onChange={e => handleUpload(e, 'idFile')}
                                onBlur={formik.handleBlur}
                                className={formik.touched.idFile && formik.errors.idFile ? 'is-invalid' : ''}
                              />
                              {renderPreview()}
                              {formik.touched.idFile && formik.errors.idFile ? (
                                <FormFeedback>{formik.errors.idFile}</FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </FormGroup>
                      )}
                    </div>
                    <Row>
                      <Col xs="12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-center">
                          <Button
                            color="primary"
                            type="submit"
                            className="btn-rounded p-4 pb-2 pt-2"
                          >
                            Save
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}

export default AddAdvisor
