//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
//export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_LOGIN = "/auth/login"
export const POST_FAKE_JWT_LOGIN = "/auth/login"
export const POST_FAKE_PASSWORD_FORGET = "/auth/forgot-password"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//Mails
export const GET_INBOX_MAILS = "/inboxmails"
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail"
export const DELETE_INBOX_MAIL = "/delete/inboxmail"

//starred mail
export const GET_STARRED_MAILS = "/starredmails"

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails"

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails"

//Send mail
export const GET_SENT_MAILS = "/sentmails"

//Trash mail
export const GET_TRASH_MAILS = "/trashmails"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/orders"
export const ADD_NEW_ORDER = "/add/order"
export const UPDATE_ORDER = "/update/order"
export const DELETE_ORDER = "/delete/order"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"
export const ADD_NEW_CUSTOMER = "/add/customer"
export const UPDATE_CUSTOMER = "/update/customer"
export const DELETE_CUSTOMER = "/delete/customer"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"
export const ADD_NEW_PROJECT = "/add/project"
export const UPDATE_PROJECT = "/update/project"
export const DELETE_PROJECT = "/delete/project"

//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS
//export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"
export const ADD_NEW_USER = "/add/user"
export const UPDATE_USER = "/update/user"
export const DELETE_USER = "/delete/user"

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data"
export const GET_YEARLY_DATA = "/yearly-data"
export const GET_MONTHLY_DATA = "/monthly-data"

export const TOP_SELLING_DATA = "/top-selling-data"

export const GET_EARNING_DATA = "/earning-charts-data"

export const GET_PRODUCT_COMMENTS = "/comments-product"

export const ON_LIKNE_COMMENT = "/comments-product-action"

export const ON_ADD_REPLY = "/comments-product-add-reply"

export const ON_ADD_COMMENT = "/comments-product-add-comment"

//company urls added
export const ADD_NEW_COMPANY = "/company"
export const GET_COMPANY = "/company"
export const UPDATE_COMPANY = "/company"
export const DELETE_COMPANY = "/company"

//highlights urls added
export const ADD_NEW_HIGHLIGHTS = "/highlight"
export const GET_HIGHLIGHTS = "/auth/highlight"
export const UPDATE_HIGHLIGHTS = "/highlight"
export const DELETE_HIGHLIGHTS = "/highlight"

//news urls added
export const ADD_NEW_NEWS = "/news"
export const GET_NEWS = "/auth/news"
export const UPDATE_NEWS = "/news"
export const DELETE_NEWS = "/news"

//insights urls added
export const ADD_NEW_INSIGHTS = "/insight"
export const GET_INSIGHTS = "/auth/insight"
export const UPDATE_INSIGHTS = "/insight"
export const DELETE_INSIGHTS = "/insight"

//reports urls added
export const ADD_NEW_REPORTS = "/report"
export const GET_REPORTS = "/auth/report"
export const UPDATE_REPORTS = "/report"
export const DELETE_REPORTS = "/report"

//reports urls added
export const ADD_NEW_USERS = "/user/add"
export const GET_USERS = "/user"
export const GET_DELETED_USERS = "/deleteduser"
export const DELETE_USER_REQUEST = "/deleteuser"
export const UPDATE_USERS = "/user"
export const DELETE_USERS = "/deleteuser"

// Upload Document
export const UPLOAD_DOCUMENT = "/documentUpload"

// Get Language
export const GET_LANGUAGE = "/auth/language"

// Get Sustain Goals
export const GET_SUSTAIN_GOAL = "/sustaingoal"

// Delete section entry
export const DELETE_SECTION_ENTRY = "/sectionentries"

export const VERIFY_USERS = "/verifyuser"

export const REJECT_USERS = "/user/documentreject"

// Get Country
export const GET_COUNTRY = "/country"

//Get interestnfovourite
export const GET_INTERESTNFAVOURITE = "/interestnfovourite"

export const GET_TRANSACTION = "/transaction"

export const GET_ACTIVECHATREQUESTHISTORY = "/activechatrequesthistory"

export const UPDATE_TRANSACTION = "/transaction"

export const PORTFOLIO = "/portfolio"

export const GET_COMPLAINTS = "/complaint"

export const FREEZE_USER = "/user/freeze"

export const DELETE_USERROLE = "/deleteuser"

export const GET_NOTIFICATIONS = "/notification"
