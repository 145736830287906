import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import { Container, Button, Card, CardBody, Col, Row, Badge } from "reactstrap"
import { useHistory } from "react-router-dom"
import { getHighlights as onGetHighlights } from "store/highlights/actions"
import { useDispatch, useSelector } from "react-redux"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter"

const index = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { highlights, error } = useSelector(state => ({
    highlights: state.Highlights.highlights,
    error: state.Highlights.error,
  }))
  const [highlightsList, setHighlightsList] = useState(highlights.filter((item) => { return item.eventType !== "communication" }))

  const pageOptions = {
    sizePerPage: 5,
    totalSize: highlights.length, // replace later with size(orders),
    custom: true,
  }

  const highlightColumns = () => [
    {
      dataField: "id",
      text: "Highlight Id",
      sort: true,
    },
    {
      dataField: "name",
      text: "Title",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "eventType",
      text: "Type",
      sort: true,
    },
    {
      dataField: "country",
      text: "Country",
      sort: true,
    },
    {
      dataField: "eventdate",
      text: "Date and Time",
      sort: true,
    },
    {
      dataField: "view",
      isDummyField: true,
      text: "View Details",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Button
          type="button"
          color="primary"
          className="btn-sm btn-rounded"
          onClick={() => {
            history.push(`/dashboard-highlights-details?id=${row.highlightcode}`)
          }}
        >
          View Details
        </Button>
      ),
    },
  ]

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ]

  useEffect(() => {
    dispatch(onGetHighlights())
  }, [])

  useEffect(() => {
    setHighlightsList(highlights.filter((item) => { return item.eventType !== "communication" }))
  }, [highlights])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Transactions Dashboard</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Dashboards" breadcrumbItem="Highlights" />

          <Card>
            <CardBody>
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={highlightColumns()}
                data={highlightsList}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    data={highlightsList}
                    columns={highlightColumns()}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField="id"
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                filter={filterFactory()}
                              />
                            </div>
                          </Col>
                        </Row>
                        <div className="pagination pagination-rounded justify-content-end">
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default index
