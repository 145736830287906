import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import DashboardTransactions from "../pages/Dashboard-Transactions/index"
import DashboardHighlights from "../pages/Dashboard-Highlights/index"
import DashboardHighlightsDetails from "../pages/Dashboard-Highlights-Details/index"
import UsersDetails from "../pages/UserDetails/index"
import AddCompany from "pages/CompanyDetails/AddCompany"
import AddNews from "../pages/News/AddNews"
import AddReports from "pages/Reports/AddReports"
import AddEventHighlights from "pages/Highlights/AddEventHighlights"
import AddCommHighlights from "pages/Highlights/AddCommHighlights"
import Users from "pages/Users"
import UserDetails from "pages/UserDetails"
import LegalEntityDetails from "pages/CompanyDetails"
import AdvisorDetails from "pages/AdvisorDetails"
import Companies from "pages/CompanyDetails/Companies"
import ComapnyDetailsPage from "pages/CompanyDetails/CompanyDetailsPage"
import Templates from "pages/Templates"
import AddInsights from "pages/Insights/AddInsights"
import AddUsers from "pages/Users/AddUsers"
import Complaints from "pages/Complaints/Complaints"
import TransactionDetails from "pages/Transaction-details/TransactionDetails"
import UserDeletion from "pages/UserDeletion"
import UserRole from "pages/UserRole"
import AddUserRole from "pages/UserRole/AddUserRole"
import EmbeddedApp from "pages/Embedded/EmbeddedApp"
import AddAdvisor from "pages/AddAdvisor"
import AddInvestor from "pages/AddInvestor"
import AddLr from "pages/AddLr"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/dashboard-highlights", component: DashboardHighlights },
  {
    path: "/dashboard-highlights-details",
    component: DashboardHighlightsDetails,
  },
  { path: "/dashboard-transactions", component: DashboardTransactions },
  { path: "/transaction-details", component: TransactionDetails },
  // users
  { path: "/users", component: Users },
  { path: "/user-deletion", component: UserDeletion },
  { path: "/users-details", component: UserDetails },
  { path: "/legal-entity-details", component: LegalEntityDetails },
  { path: "/advisor-details", component: AdvisorDetails },
  { path: "/add-company", component: AddCompany },
  { path: "/company", component: Companies },
  { path: "/company-details", component: ComapnyDetailsPage },
  { path: "/templates", component: Templates },
  { path: "/add-news", component: AddNews },
  { path: "/add-users", component: AddUsers },
  { path: "/add-reports", component: AddReports },
  { path: "/add-eventHighlights", component: AddEventHighlights },
  { path: "/add-communicationHighlights", component: AddCommHighlights },
  { path: "/add-insights", component: AddInsights },
  // //profile
  { path: "/profile", component: UserProfile },
  { path: "/complaints", component: Complaints },
  { path: "/userRole", component: UserRole },
  { path: "/add-userRole", component: AddUserRole },
  { path: "/onboard-users", component: EmbeddedApp },
  { path: "/add-advisor", component: AddAdvisor },
  { path: "/add-investor", component: AddInvestor },
  { path: "/add-lr", component: AddLr },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/templates" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { publicRoutes, authProtectedRoutes }
