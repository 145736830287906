import React, { useState, useRef, useEffect } from "react"
import MetaTags from "react-meta-tags"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import {
  Container,
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Badge,
  UncontrolledTooltip,
  CardTitle,
} from "reactstrap"
import { withRouter, Link } from "react-router-dom"
import DeleteModal from "../../components/Common/DeleteModal"
import { getInterestNFavouriteById as onGetInterestNFavouriteById } from "store/interestnfavourite/actions"
import { useDispatch, useSelector } from "react-redux"

const index = props => {
  const dispatch = useDispatch()
  const searchParams = new URLSearchParams(location.search)
  const highlightId = searchParams.get("id")

  const { interestNFavouriteById, error } = useSelector(state => ({
    interestNFavouriteById: state.Interestnfavourite.interestnfavouriteById,
    error: state.Interestnfavourite.error,
  }))

  const [interestedPeopleList, setInterestedPeopleList] = useState(
    interestNFavouriteById
  )
  const [interestPeopleDetails, setInterestPeopleDetails] = useState([])

  const [tableData, setTableData] = useState([])

  const reports = [
    // { title: "Number of people Invited", description: "5,235" },
    { title: "Number of people Interested", description: tableData.length },
  ]

  const pageOptions = {
    sizePerPage: 5,
    totalSize: tableData.length, // replace later with size(orders),
    custom: true,
  }

  useEffect(() => {
    if (highlightId !== null && highlightId !== undefined) {
      //call api to get company data;
      dispatch(onGetInterestNFavouriteById(highlightId))

      console.log("InterestPeopleDetails", interestPeopleDetails, highlightId)
    }
  }, [highlightId])

  useEffect(() => {
    console.log(interestNFavouriteById, "inside setstate")
    const dataTable = interestNFavouriteById?.filter(
      temp => temp?.type === "interested"
    )
    setInterestPeopleDetails(dataTable)
  }, [interestNFavouriteById])

  let temp = []

  useEffect(() => {
    if (interestPeopleDetails.length !== 0) {
      console.log('interestPeopleDetails', interestPeopleDetails);
      interestPeopleDetails.map(item => {
        if (item.UserDetail !== null && item.UserDetail.type !== "admin") {
          temp.push({
            name: item.UserDetail.role === "legalrepresent"
              ? item.UserDetail?.lrDetails[0]?.companyname
              : item.UserDetail.userdetail.firstname +
                " " +
                item.UserDetail.userdetail.lastname,
            // lastname: item.UserDetail.userdetail.lastname,
            id: item.UserDetail.id,
            email: item.UserDetail.email,
            role: item.UserDetail.role === 'legalrepresent' ? 'Legal Representative' : item.UserDetail.role,
            createdOn: item.createdOn,
            // name:
            //   item.Buyer_Detail.role === "legalrepresent"
            //     ? item.Buyer_Detail?.lrDetails[0]?.companyname
            //     : item.Buyer_Detail.userdetail.firstname +
            //       " " +
            //       item.Buyer_Detail.userdetail.lastname,
          })
        }
      })
      setTableData(temp)
      console.log(temp, "TableData")
    }
  }, [interestPeopleDetails])

  const interestedPeopleColumns = () => [
    {
      dataField: "id",
      text: "User Id",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email ID",
      sort: true,
    },
    // {
    //   dataField: "orderdate",
    //   text: "Date",
    //   sort: true,
    // },
    {
      dataField: "role",
      text: "User Role",
      sort: true,
    },
    {
      dataField: "createdOn",
      text: "Inetrested Date",
      sort: true,
    },
  ]

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ]

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete order
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = selectedData => {
    setDeleteModal(true)
  }

  const handleDeleteOrder = () => {
    onPaginationPageChange(1)
    setDeleteModal(false)
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Highlight Details</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Highlights" breadcrumbItem="Highlights Details" />

          <Row>
            <Col xl="12">
              <Row>
                {reports.map((report, key) => (
                  <Col md="6" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{tableData.length}</h4>
                          </div>
                          {/* <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div> */}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>

          <Card>
            <CardBody>
              <CardTitle className="mb-4">Interested People</CardTitle>
              {tableData.length > 0 && (
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={interestedPeopleColumns()}
                  data={tableData}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={tableData}
                      columns={interestedPeopleColumns()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField="id"
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  // selectRow={selectRow}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>
                          <div className="pagination pagination-rounded justify-content-end">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default index
