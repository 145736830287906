import axios from "axios"
import { getAccessToken } from "./jwt-token-access/accessToken"
import authHeader from "./jwt-token-access/auth-token-header"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

//apply base url for axios
// const API_URL = "https://asia-impact-server.onrender.com/asiaimpact/v1"
const API_URL = "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1"

const axiosApi = axios.create({
  baseURL: API_URL,
})
const linkArray = ["/auth/login", "/auth/forgot-password"] //specific ignore token link
//axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  if (!linkArray.includes(url)) {
    axiosApi.defaults.headers.common["Authorization"] = getAccessToken()
  }
  return await axiosApi.get(url, { ...config }).then(response => {
    return response.data
  })
}

export async function post(url, data, config = {}) {
  console.log("post", data)
  // console.log(data.get("file"))
  const payload = Array.isArray(data) ? data : { ...data }
  if (!linkArray.includes(url)) {
    axiosApi.defaults.headers.common["Authorization"] = getAccessToken()
  }
  return await axiosApi
    .post(url, payload, { ...config })
    .then(response => {
      toast.success(response.message)
      return response.data
    })
    .catch(error => {
      toast.error(error.response.data.error) // Show error toaster message
      throw error
    })
}
export async function postDocument(url, data, config = {}) {
  console.log("post", data)
  // console.log(data.get("file"))
  if (!linkArray.includes(url)) {
    axiosApi.defaults.headers.common["Authorization"] = getAccessToken()
  }
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => {
      // toast.success(response.message);
      console.log("postDocument response", response.data)
      return response.data
    })
    .catch(error => {
      toast.error(error.response.data.error) // Show error toaster message
      throw error
    })
}

export async function put(url, data, config = {}) {
  const payload = Array.isArray(data) ? data : { ...data }
  if (!linkArray.includes(url)) {
    axiosApi.defaults.headers.common["Authorization"] = getAccessToken()
  }
  return await axiosApi
    .put(url, payload, { ...config })
    //.then(response => response.data)
    .then(response => {
      toast.success(response.message)
      return response.data
    })
    .catch(error => {
      toast.error(error.response.data.error) // Show error toaster message
      throw error
    })
}

export async function del(url, config = {}) {
  console.log("delete", url)
  if (!linkArray.includes(url)) {
    axiosApi.defaults.headers.common["Authorization"] = getAccessToken()
  }
  return await axiosApi
    .delete(url, { ...config })
    //.then(response => response.data)
    .then(response => {
      toast.success(response.message)
      return response.data
    })
    .catch(error => {
      toast.error(error.response.data.error) // Show error toaster message
      throw error
    })
}

export async function patch(url, data, config = {}) {
  console.log("patch", url)
  if (!linkArray.includes(url)) {
    axiosApi.defaults.headers.common["Authorization"] = getAccessToken()
  }
  return await axiosApi
    .patch(url, { ...data }, { ...config })
    //.then(response => response.data)
    .then(response => {
      toast.success(response.message)
      return response.data
    })
    .catch(error => {
      toast.error(error.response.data.error) // Show error toaster message
      throw error
    })
}
